import { Typography } from "antd"
import { useTranslation } from "next-i18next"
import { FC } from "react"

type Props = {
  description: string
}

export const ArtworkDescription: FC<Props> = ({ description }) => {
  const { t } = useTranslation()
  return(
    <div style={{ padding: "24px", display: "flex", flexDirection: "column", gap: "16px", border: "1px #ECECEC solid", whiteSpace: "pre-line" }}>
      <Typography.Text style={{ fontWeight: "bold" }}>{t("説明")}</Typography.Text>
      <Typography.Text style={{ lineHeight: 1.5, letterSpacing: "0.8px" }}>{description}</Typography.Text>
    </div>
  )
}
