import { TFunction } from "next-i18next"

/**
 * 言語に応じて作品の技法一覧を返す関数
 */
export const getArtWorkTechniques = (artWork: Pick<ArtWork, "techniques">, langType: string, t: TFunction): string | undefined => {
  if (langType === "en") return artWork.techniques.map(v => v.name_en).join(t("、"))
  // フォールバック先は description
  return artWork.techniques.map(v => v.name).join(t("、"))
}
