import { message } from "antd"
import api from "api"
import { Button } from "components/shared/Button"
import { useAuth } from "contexts/auth"
import { useIsFollowing } from "hooks/artists"
import { useTranslation } from "next-i18next"
import { VFC } from "react"

type Props = {
  artistId: string
  size?: "sm" | "md" | "lg"
  withUnFollow?: boolean
}

export const FollowButton: VFC<Props> = ({ artistId, size = "sm", withUnFollow = false }) => {
  const { t } = useTranslation()
  const { isAuthenticated, setIsSignInOpened, setSignInNotification } = useAuth()
  const { data: isFollowing, mutate } = useIsFollowing(artistId)
  const onClickFollowButton = async () => {
    if (!isAuthenticated) {
      setIsSignInOpened(true)
      setSignInNotification(t("お気に入りを利用するには、ログインが必要です。"))
      return
    }

    if (isFollowing) {
      const { data, error }: { data: any; error: any } = await api.delete(`/artists/${artistId}/unfollow`)
      if (data) {
        message.success(t("フォローを解除しました"))
        mutate()
      }
      if (error) {
        message.error(error.data.error)
      }
    } else {
      const { data, error }: { data: any; error: any } = await api.post(`/artists/${artistId}/follow`)
      if (data) {
        message.success(t("フォローしました"))
        mutate()
      }
      if (error) {
        message.error(error.data.error)
      }
    }
  }

  if (!isFollowing) {
    return (
      <Button
        type="secondary"
        size={size}
        onClick={(e) => {
          e.preventDefault()
          onClickFollowButton()
        }}
      >
        {t("フォローする")}
      </Button>
    )
  }

  if (!withUnFollow) {
    return (
      <Button size={size} disabled>
        {t("フォロー中")}
      </Button>
    )
  }

  return (
    <Button
      type="primary"
      size={size}
      onClick={(e) => {
        e.preventDefault()
        onClickFollowButton()
      }}
    >
      {t("フォローを外す")}
    </Button>
  )
}
