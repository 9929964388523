import { Button, Radio, Space, Typography } from "antd"
import api from "api"
import { CountBox } from "components/models/orders/CountBox"
import { Spinner } from "components/shared/Spinner"
import { useAuth } from "contexts/auth"
import colors from "helpers/colors"
import { urls } from "helpers/urls"
import { useLotteriesByArtworkVariantId } from "hooks/lotteries"
import { useCartOrder } from "hooks/orders"
import { getArtWorkClassificationOtherStr } from "libs/artWorks/getArtWorkClassificationOtherStr"
import { getArtWorkTechniques } from "libs/artWorks/getArtWorkTechniques"
import { getArtWorkTitle } from "libs/artWorks/getArtWorkTitle"
import { generatePriceText } from "libs/generatePriceText"
import { getArtistName } from "libs/getArtistName"
import { getClassificationName } from "libs/getClassificationName"
import { find, isEmpty } from "lodash"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import CartIcon from "public/icons/cart.svg"
import { useCallback, useEffect, useMemo, useState, VFC } from "react"
import styled from "styled-components"

import { FollowButtonM } from "../../FollowButton"
import { InquiryButton } from "../../InquiryButton"
import { ArtworkCartAddModal, CartAddArtworkModel } from "./ArtworkCartAddModal"

type Props ={
  artWork: ArtWork
  artWorkVariants?: ArtWorkVariantForm[]
  artist: Artist
  handleSelectArtWorkVariant: (artWorkVariant: ArtWorkVariantForm) => void
}

type ResponseType = { data: ArtWorkVariantOrder, error: any }

export const ArtworkForm: VFC<Props> = ({ artWork, artWorkVariants, artist, handleSelectArtWorkVariant }) => {
  const { isAuthenticated, setSignInNotification, setIsSignInOpened } = useAuth()
  const { t, i18n } = useTranslation()

  const [artWorkVariantId, setArtWorkVariantId] = useState(artWorkVariants?.at(0)?.id)
  const [quantity, setQuantity] = useState(1)
  const [loading, setLoading] = useState(false)
  const [cartAddModalOpen, setCartAddModalOpen] = useState(false)
  const [cartAddModalData, setCartAddModalData] = useState<CartAddArtworkModel>()
  const [cartAddModalError, setCartAddModalError] = useState("")
  const artWorkVariant = useMemo(() => (find(artWorkVariants, ["id", artWorkVariantId])), [artWorkVariants, artWorkVariantId])
  const { data: order, mutate: mutateOrder } = useCartOrder()
  const { data: lotteries, isValidating: isValidatingLotteries } = useLotteriesByArtworkVariantId(artWorkVariant?.id)

  // NOTE: 既にカートに入っているかどうか
  const orderInCart = useMemo(() => {
    return order && order.art_work_variant_orders.find(v => v.art_work_variant.id === artWorkVariant?.id)
  }, [artWorkVariant?.id, order])

  useEffect(() => {
    if (artWorkVariant) {
      handleSelectArtWorkVariant(artWorkVariant)
    }
  }, [handleSelectArtWorkVariant, artWorkVariant])

  const handleClickAddCardButton = useCallback( async () => {
    if(!isAuthenticated){
      setSignInNotification(t("カートを利用するには、ログインが必要です。"))
      setIsSignInOpened(true)
      return
    }

    setLoading(true)

    await api.post<void, ResponseType>(
      "/orders/add_cart",
      {
        art_work_variant_id: artWorkVariantId,
        quantity: quantity,
      }
    ).then((res) => {
      mutateOrder()
      setLoading(false)

      if (res.error) {
        setCartAddModalError(res.error.data.error)
        setCartAddModalData({
          artWork,
          title: getArtWorkTitle(artWork, i18n.language) || "",
          quantity,
          total: (artWorkVariant?.publish_price || 0) * quantity,
        })
      } else {
        setCartAddModalData({
          artWork: res.data.art_work_variant.art_work,
          title: res.data.art_work_variant.title,
          quantity: res.data.quantity,
          total: res.data.total,
        })
      }

      setCartAddModalOpen(true)
    })
  }, [isAuthenticated, artWorkVariantId, quantity, setSignInNotification, t, setIsSignInOpened, mutateOrder, artWork, i18n.language, artWorkVariant?.publish_price])

  const closeArtworkCartAddModal = () => {
    setCartAddModalError("")
    setCartAddModalData(undefined)
    setCartAddModalOpen(false)
  }

  // const handleClickInquiryButton = useCallback(() => {
  //   // TODO: implement
  // }, [])

  const VariantQuantity: VFC<{ quantity: number }> = ({ quantity }) => {
    const last_one = quantity === 1
    if (quantity >= 1) {
      return (
        <span>
          <Typography.Text>・</Typography.Text>
          <Typography.Text style={{
            lineHeight: 1,
            fontWeight: last_one ? "bold" : "normal",
            color: last_one ? colors.red : colors.black
          }}>
            {t("残り")}{quantity}{t("作品")}
          </Typography.Text>
        </span>
      )
    }
    return <></>
  }

  const PurchaseForm = () => {
    const online_status = artWorkVariant?.online_status

    switch (online_status) {
    case "inquiry":
      return (
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography.Text style={{ fontSize: "14px", height: "14px", lineHeight: 1 }}>
            {t("購入についてはお問い合わせください")}
          </Typography.Text>
          <div style={{ display: "flex", gap: "16px", height: "48px" }}>
            <InquiryButton artWorkId={artWork.id} />
          </div>
          <div style={{ display: "flex", gap: "16px", height: "48px" }}>
            <FollowButtonM artWorkId={artWork.id} withUnFollow/>
          </div>
        </div>
      )
    case "disabled":
      return (
        <>
          <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Button style={{ backgroundColor: colors.grey30, borderColor: colors.grey30, width: "100%", borderRadius: "40px", height: "48px" }} type="primary" disabled>
              <Typography.Text style={{ fontSize: "16px", height: "16px", lineHeight: 1, color: "white", fontWeight: 700, marginLeft: "8px" }}>
                SOLD OUT
              </Typography.Text>
            </Button>
          </div>
          <div style={{ display: "flex", gap: "16px", height: "36px" }}>
            <FollowButtonM artWorkId={artWork.id} withUnFollow/>
            <InquiryButton artWorkId={artWork.id} />
          </div>
        </>
      )
    case "enterable":
      return (
        <>
          {artWorkVariant && (
            <Typography.Text style={{ fontSize: "28px", lineHeight: 1, fontWeight: 700 }}>
              {generatePriceText({
                currency: artWorkVariant.currency,
                publish_price: artWorkVariant.publish_price,
                is_available_online: artWorkVariant.is_available_online,
                prefix: true,
                quantity
              })}
            </Typography.Text>
          )}
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Button style={{ backgroundColor: colors.grey30, borderColor: colors.grey30, width: "100%", borderRadius: "40px", height: "48px" }} type="primary" disabled>
              <Typography.Text style={{ fontSize: "16px", height: "16px", lineHeight: 1, color: "white", fontWeight: 700, marginLeft: "8px" }}>
                {t("カートに追加する")}
              </Typography.Text>
            </Button>
            <Typography.Text style={{ fontSize: "12px", color: "#E95353", lineHeight: 1.5, fontWeight: 700 }}>
              {t("この作品を購入するにはエントリーする必要があります。")}
            </Typography.Text>
            {isValidatingLotteries ? (
              <Spinner />
            ) : lotteries?.map(lottery => {
              return (
                <div key={lottery.id} style={{ padding: "24px", display: "flex", flexDirection: "column", gap: "8px", border: "1px #ECECEC solid", marginBottom: 8, whiteSpace: "pre-line" }}>
                  <Link href={`/lotteries/${lottery.id}`} >
                    <a>
                      <Typography.Title level={1} style={{ fontSize: 14, lineHeight: 1.5 }}>{lottery.title}</Typography.Title>
                    </a>
                  </Link>
                  <Typography.Text style={{ fontSize: 12, lineHeight: 1.5 }}>{t("日時")}: {lottery.date_text}</Typography.Text>
                </div>
              )
            })
            }
          </div>
          <div style={{ display: "flex", gap: "16px", height: "36px" }}>
            <FollowButtonM artWorkId={artWork.id} withUnFollow/>
            <InquiryButton artWorkId={artWork.id} />
          </div>
        </>
      )

    case "active":
      return (
        <>
          {(artWorkVariant) && (
            <Typography.Text style={{ fontSize: "28px", lineHeight: 1, fontWeight: 700 }}>
              {generatePriceText({
                currency: artWorkVariant.currency,
                publish_price: artWorkVariant.publish_price,
                is_available_online: artWorkVariant.is_available_online,
                prefix: true,
                quantity: orderInCart?.quantity || quantity
              })}
            </Typography.Text>
          )}

          <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <div style={{ display: "flex", gap: "16px" }}>
              <CountBox
                quantity={orderInCart ? orderInCart.quantity : quantity || 1}
                setQuantity={setQuantity}
                maxQuantity={artWorkVariant?.quantity}
                disabled={!!orderInCart}
                style={{ height: "48px", padding: "16px" }}
              />
              <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "8px" }}>
                <StyledPrimaryButton
                  loading={loading}
                  type="primary"
                  disabled={!!orderInCart}
                  style={{ width: "100%", fontSize: "16px", lineHeight: 1.5, fontWeight: 700, display: "flex", alignItems: "center", justifyContent: "center" }}
                  onClick={handleClickAddCardButton}
                >
                  <CartIcon style={{ height: "16px", width: "16px", fontSize: "16px", marginRight: "8px" }} />
                  {t("カートに追加する")}
                </StyledPrimaryButton>
                {orderInCart && (
                  <Typography.Text style={{ fontSize: "12px", color: "#E95353", lineHeight: 1.5, fontWeight: 700 }}>
                    {t("この作品はすでにカートに入っています。お一人様一点限りご注文できます。")}
                  </Typography.Text>
                )}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "16px", height: "36px" }}>
            <FollowButtonM artWorkId={artWork.id} withUnFollow/>
            <InquiryButton artWorkId={artWork.id} />
          </div>
        </>
      )
    default:
      return null
    }
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "8px", paddingBottom: "24px", borderBottom: "1px solid #C4C4C4" }}>
          <Typography.Text style={{ textDecoration: "underline" }}>
            {getArtistName(artist, i18n.language)}
          </Typography.Text>
          <Typography.Title style={{ fontSize: "28px", lineHeight: 1.5, fontWeight: 700 }} >
            {getArtWorkTitle(artWork, i18n.language)}
          </Typography.Title>
          {artWork?.creation_year_humanize && (
            <Typography.Title level={2} style={{ fontSize: "14px", lineHeight: 1.5, fontWeight: 500 }} >
              {artWork.creation_year_humanize}
            </Typography.Title>
          )}
        </div>

        {artWorkVariants && artWorkVariants.length > 1 && (
          <div>
            <div style={{ paddingBottom: "16px" }}>
              <Typography.Text style={{ fontSize: "14px", lineHeight: 1, fontWeight: 700 }}>
                {t("種類を選択")}
              </Typography.Text>
            </div>
            <Radio.Group value={artWorkVariantId}>
              <Space direction="vertical" size={20}>
                {artWorkVariants.map((artWorkVariant) => {
                  return (
                    <Radio
                      key={artWorkVariant.id}
                      value={artWorkVariant.id}
                      onChange={() => setArtWorkVariantId(artWorkVariant.id)}
                      style={{ alignItems: "center" }}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography.Text style={{ paddingBottom: "8px", lineHeight: 1, fontWeight: artWorkVariantId === artWorkVariant.id ? "bold" : "normal" }}>
                          {artWorkVariant.title}
                        </Typography.Text>
                        {artWorkVariant.publish_price && artWorkVariant.online_status === "active" &&
                          <span>
                            <Typography.Text style={{ lineHeight: 1 }}>
                              {generatePriceText({
                                currency: artWorkVariant.currency,
                                publish_price: artWorkVariant.publish_price,
                                is_available_online: artWorkVariant.is_available_online,
                                prefix: true,
                              })}
                            </Typography.Text>
                            <VariantQuantity quantity={artWorkVariant?.quantity || 0} />
                          </span>
                        }
                      </div>
                    </Radio>
                  )
                })}
              </Space>
            </Radio.Group>
          </div>
        )}

        <PurchaseForm />

        <div style={{ paddingTop: "24px", borderTop: "1px solid #ECECEC" }}>
          <div style={{ paddingBottom: "16px" }}>
            <Typography.Title level={2} style={{ fontSize: "14px", lineHeight: 1, fontWeight: 700 }}>
              {t("詳細")}
            </Typography.Title>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px", minWidth: "64px" }}>
              {artWork?.classification?.name && (
                <DetailText>
                  {t("分類")}
                </DetailText>
              )}
              {(!isEmpty(artWork?.techniques) || getArtWorkClassificationOtherStr(artWork, i18n.language)) && (
                <DetailText>
                  {t("技法")}
                </DetailText>
              )}
              {artWork?.size && (
                <DetailText>
                  {t("サイズ")}
                </DetailText>
              )}
              {artWork?.owner.name && (
                <DetailText>
                  {t("取り扱い")}
                </DetailText>
              )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              {artWork.classification?.name && (
                <DetailText>
                  {getClassificationName(artWork.classification, i18n.language)}
                </DetailText>
              )}
              {(artWork.techniques.length > 0 || getArtWorkClassificationOtherStr(artWork, i18n.language)) &&
                <DetailText>
                  {getArtWorkClassificationOtherStr(artWork, i18n.language) ? getArtWorkClassificationOtherStr(artWork, i18n.language) : getArtWorkTechniques(artWork, i18n.language, t)}
                </DetailText>
              }
              {artWork.size && (
                <DetailText>
                  {artWork?.size}
                </DetailText>
              )}
              <Link href={urls.owner(artWork.owner.id)} passHref>
                <a title={artWork.owner.name} style={{ lineHeight: 1 }}>
                  <DetailText>
                    {artWork.owner.name}
                  </DetailText>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <ArtworkCartAddModal
        visible={cartAddModalOpen}
        error={cartAddModalError}
        data={cartAddModalData}
        onClose={closeArtworkCartAddModal}
      />
    </>
  )
}

const StyledPrimaryButton = styled(Button)`
  height: 48px;
  border-radius: 40px;
  background: black;
  border-color: black;

  &:hover {
   color: ${colors.primary};
   background: white;
   border-color: black;
  }
`
const DetailText = styled(Typography.Text)`
  font-size: 14px;
  line-height: 1;
`
