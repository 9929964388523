import { Form, Input, Modal, ModalProps, Typography } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import { Button }from "components/shared/Button"
import { useCreateInquiry } from "hooks/inquiries/useCreateInquiry"
import { useTranslation } from "next-i18next"
import { FC, useCallback } from "react"

type Props = {
  artWorkId: string
}
export const InquiryModal: FC<ModalProps & Props> = ({ visible, onCancel, artWorkId }) => {
  const { xs } = useBreakpoint()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const { isSendingRequest, createInquiry } = useCreateInquiry()

  const handleFinish = useCallback((values) => {
    createInquiry(artWorkId, values.message_text)
  }, [artWorkId, createInquiry])

  return (
    <Modal
      title={<Typography.Text style={{ fontSize: "16px", lineHeight: 1.5, fontWeight: 700 }}>{t("お問い合わせ")}</Typography.Text>}
      footer={null}
      visible={visible}
      width={xs ? 343 : 612}
      onCancel={onCancel}
      bodyStyle={xs ? { paddingRight: 16, paddingLeft: 16 } : { paddingRight: 24, paddingLeft: 24 }}
    >
      <Form onFinish={handleFinish} form={form}>
        <Form.Item name="message_text" required rules={[{ required: true, message: t("テキストを入力してください") }]}>
          <Input.TextArea placeholder={t("お問い合わせ内容を入力してください")} rows={4} />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            style={{ padding: "12px 16px", fontSize: "16px" }}
            size="sm"
            type="primary"
            disabled={isSendingRequest}
            onClick={() => form.submit()}
          >
            {t("送信")}
          </Button>
        </div>
      </Form>
    </Modal>

  )

}
