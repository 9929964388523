import colors from "helpers/colors"
import MinusIcon from "public/icons/minus.svg"
import PlusIcon from "public/icons/plus.svg"
import { useEffect, useState, VFC } from "react"
import { CSSProperties } from "styled-components"

type Props = {
  quantity: number,
  setQuantity: (value: number) => void,
  maxQuantity?: number,
  disabled?: boolean
  style?: CSSProperties
}

export const CountBox: VFC<Props> = ({ quantity, setQuantity, maxQuantity = 0, disabled = false, style }) => {
  const isActiveMinusIcon = quantity > 1 && !disabled
  const isActivePlusIcon = quantity < maxQuantity && !disabled
  const [newQuantity, setNewQuantity] = useState(quantity)

  useEffect(() => {
    setQuantity(newQuantity)
  }, [newQuantity, setQuantity])

  return (
    <div style={{
      ...style,
      display: "flex",
      justifyContent: "center",
      gap: "12px",
      alignItems: "center",
      borderRadius: "1px",
      border: `1px ${colors.border} solid`,
    }}>
      <MinusIcon
        style={{ color: isActiveMinusIcon ? colors.black : "#C4C4C4", cursor: isActiveMinusIcon ? "pointer" : "not-allowed", width: "12px", height: "12px" }}
        onClick={isActiveMinusIcon ? () => setNewQuantity(prevValue => prevValue - 1) : undefined}
      />
      <span style={{ fontSize: "16px", fontWeight: 400, lineHeight: "16px" }}>{newQuantity}</span>
      <PlusIcon
        style={{ color: isActivePlusIcon ? colors.black : "#C4C4C4", cursor: isActivePlusIcon ? "pointer" : "not-allowed", width: "12px", height: "12px" }}
        onClick={isActivePlusIcon ? () => setNewQuantity(prevValue => prevValue + 1) : undefined}
      />
    </div>
  )
}
