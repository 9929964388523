import { Image } from "antd"
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint"
import colors from "helpers/colors"
import ArrowNextIcon from "public/icons/arrow/next.svg"
import ArrowPrevIcon from "public/icons/arrow/prev.svg"
import ZoomOutIcon from "public/icons/zoom/out.svg"
import { FC, useCallback, useRef, useState } from "react"
import styled from "styled-components"

import { NoImageDiv } from "../NoImageDiv"

type Props = {
  images: ArtWorkImage[]
}

export const ImageViewer: FC<Props> = ({ images }) => {
  const { xs } = useBreakpoint()
  const listRef = useRef<HTMLDivElement>(null)

  const [activeIndex, setActiveIndex] = useState(0)
  const [previewUrl, setPreviewUrl] = useState<string>()

  const scrollLeft = useCallback((position:number) => {
    if (!listRef.current) return

    listRef.current.scroll({
      left: position,
      behavior: "smooth"
    })
  }, [])

  const handlePrev = () => {
    if (activeIndex === 0) {
      setActiveIndex(images.length - 1)
      scrollLeft(listRef.current?.scrollWidth || 0)
    } else {
      setActiveIndex((prev) => prev - 1)
      scrollLeft((listRef.current?.scrollLeft || 0) - (xs ? 52 : 100))
    }
  }

  const handleNext = () => {
    if (activeIndex === images.length - 1) {
      setActiveIndex(0)
      scrollLeft(0)
    } else {
      setActiveIndex((prev) => prev + 1)
      scrollLeft((listRef.current?.scrollLeft || 0) + (xs ? 52 : 100))
    }
  }

  if (images.length === 0) return (
    <div style={{ alignSelf: "center", width: xs ? "100%" : "70%", aspectRatio: "3 / 4" }}>
      <NoImageDiv type="artwork" />
    </div>
  )

  return (
    <div style={{ height: xs ? "390px" : "596px" }}>
      <div style={{ display: "flex", flexDirection: "column", gap: "16px", height: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 8px",
            background: "#F5F5F5",
            gap: "10px",
            width: "100%",
            flex: 1,
            position: "relative"
          }}
        >
          {images.length >= 2 && (
            <div>
              <StyledArrowPrevIcon
                style={{ height: "32px", width: "32px", fontSize: "32px", color: colors.grey, cursor: "pointer" }}
                onClick={handlePrev}
              />
            </div>
          )}
          <div style={{ height: "100%", margin: "auto" }}>
            <img
              src={images[activeIndex].image_url}
              height={xs ? "322px" : "480px"}
              width="100%"
              style={{ objectFit: "contain", userSelect: "none" }}
            />
          </div>
          {images.length >= 2 && (
            <div>
              <StyledArrowNextIcon
                style={{ height: "32px", width: "32px", color: colors.grey, cursor: "pointer" }}
                onClick={handleNext}
              />
            </div>
          )}
          <StyledZoomOutIcon
            onClick={() => setPreviewUrl(images[activeIndex].image_url)}
            style={{
              position: "absolute",
              height: "24px",
              right: "20px",
              bottom: "20px",
              fontSize: "24px",
              width: "24px",
              cursor: "pointer",
              color: colors.grey,
            }}
          />
        </div>

        <StyledImageWrapper
          ref={listRef}
          style={{
            height: xs ? "52px" : "100px",
            paddingLeft: "16px",
            display: "flex",
            gap: xs ? "12px" : "8px",
            overflow: "scroll",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {images.map((image, index) => {
            return (
              <div
                key={image.id}
                style={{
                  height: xs ? "52px" : "100px",
                  width: xs ? "52px" : "100px",
                }}
                onClick={() => setActiveIndex(index)}
              >
                <img
                  src={image.image_url}
                  style={{
                    background: "#F5F5F5",
                    objectFit: "contain",
                    height: xs ? "52px" : "100px",
                    width: xs ? "52px" : "100px",
                    border: index === activeIndex ? "1px black solid" : "",
                    cursor: index === activeIndex ? "default" : "pointer",
                  }}
                />
              </div>
            )
          })}
        </StyledImageWrapper>
      </div>
      <Image
        width={200}
        height={0}
        style={{ display: "none" }}
        src={images[activeIndex].image_url}
        preview={{
          visible: Boolean(previewUrl),
          onVisibleChange: () => setPreviewUrl(undefined),
        }}
        loading="lazy"
      />
    </div>
  )
}

const StyledArrowPrevIcon = styled(ArrowPrevIcon)`
  &:hover {
    opacity: 0.7;
  }
`

const StyledArrowNextIcon = styled(ArrowNextIcon)`
  &:hover {
    opacity: 0.7;
  }
`

const StyledZoomOutIcon = styled(ZoomOutIcon)`
  &:hover {
    opacity: 0.7;
  }
`

const StyledImageWrapper = styled.div`
  ::-webkit-scrollbar {
    display: none; /*Google Chrome、Safari、Microsoft Edge対応のスクロールバー非表示コード*/
  }
`
